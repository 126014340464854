// React specific
import React from 'react';
import { useIntl } from 'react-intl';
// Components
import Loading from '../../../components/Loading';

// Services and state
import { useJsonApi } from '../../../services/GlobalStore/GlobalStore';

// Model
import ClaimDetailsSubheader from './ClaimDetailsSubheader';
import getTranslatedClaimFlightStatus from '../../../services/resources/claimGetters/filterFlightStatus';
import getClaimFlightsSchedule from '../../../services/resources/claimGetters/filterFlightRoute';
import { getIdFromNumber } from '../../ClaimPage/Claim';

/**
 * Component show details for one claim
 */
const ClaimContainer = ({
  claimNumber,
  children,
}: {
  claimNumber: string;
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  const claimId = getIdFromNumber(claimNumber);
  const { formatMessage } = useIntl();
  const { data: claim, loading: claimLoading } = useJsonApi(
    `claims/${claimId}`,
    {
      credentials: 'include',
      cachePolicy: 'no-cache',
      queryParams: {
        include: [
          'customer',
          'currentState',
          'placeOfJurisdiction',
          'passengers',
          'passengers.person',
          'customerFlights.airline',
          'customerFlights.departureAirport',
          'customerFlights.departureAirport.city',
          'customerFlights.arrivalAirport',
          'customerFlights.arrivalAirport.city',
          'flights.airline',
          'flights.departureAirport',
          'flights.departureAirport.city',
          'flights.arrivalAirport',
          'flights.arrivalAirport.city',
          'liableOrganization',
          'claimValidationFlights.flight',
        ].join(),
      },
    },
    []
  );

  if (claimLoading || !claim)
    return (
      <div className={'flex justify-center'}>
        <Loading />
      </div>
    );

  return (
    <>
      <ClaimDetailsSubheader
        claimNumber={claimNumber}
        flightStatus={formatMessage(getTranslatedClaimFlightStatus(claim?.[0]))}
        customerFlights={getClaimFlightsSchedule(claim?.[0])}
      />
      {children}
    </>
  );
};
export default ClaimContainer;
